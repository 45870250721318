const defaultThemeColor = '#5c5c5c'
const defaultLogoColor = '#eee'

/*
* domainIncludes: matches domain host to detect instance automatically
* logoUrl: url to the logo file
* bgUrl: url to the background image used on the left side 50% of the page
* showLogoOnLoginBoxHeader: show logo on top of the login box, instead the title
* logoBgColor: background color behind the large logo on the left 50% of the page
* themeColor: color for buttons and login box header
* showLeftLogoCol: show left 50% of the logo placement area
* bodyBgColor: bg color fot the whole page. Fills 100%, instead of the 50% by logoBgColor
* light: lighter version, removes some shadows etc
* title: overwrite default login title
* */

const lightThemeRequiredProps = {
  loginBoxBgColor: 'white',
  light: true,
  bodyBgColor: '#F2F2F2',
}

export default {
  default: { // 'dev.test'
    domainIncludes: [],
    logoUrl: '/login/img/aava-logo-light.png',
    logoBgColor: '#ddd', // Default that was set in Aava-Portal
    themeColor: '#bbb', // Default that was set in Aava-Portal
  },
  lappset: {
    ...lightThemeRequiredProps,
    domainIncludes: ['lappset', 'playcare'],
    logoUrl: '/login/img/lappset_logo.png',
    bgUrl: '/login/img/lappset_bg.jpg',
    showLogoOnLoginBoxHeader: false,
    themeColor: '#00b6fa',
  },
  ferrum: {
    domainIncludes: ['ferrum'],
    logoUrl: '/login/img/Ferrum-Steel-OY-logo.png',
    logoBgColor: defaultLogoColor,
    themeColor: defaultThemeColor,
  },
  'st-koneistus': {
    domainIncludes: ['st-koneistus', 'koneistus'],
    logoUrl: '/login/img/ST-koneistus_logo_CMYK.jpeg',
    logoBgColor: 'white',
    themeColor: defaultThemeColor,
  },
  janla: {
    domainIncludes: ['janla'],
    logoUrl: '/login/img/Janla-OY-Logo.jpeg',
    logoBgColor: defaultLogoColor,
    themeColor: defaultThemeColor,
  },
  oma: {
    domainIncludes: ['oma.aava', 'localhost:8080', 'metal.aava'], // 'dev.test'
    logoUrl: '/login/img/logo-aava-reversed.png',
    bgUrl: '/login/img/oma-aava.jpg',
    showLogoOnLoginBoxHeader: false,
    themeColor: 'orange',
    bodyBgColor: '#F2F2F2',
    ...lightThemeRequiredProps,
  },
  eskomatic: {
    domainIncludes: ['eskomatic'],
    logoUrl: '/login/img/Eskomatic-oy-logo.svg',
    logoBgColor: 'black',
    themeColor: 'black',
  },
  starclub: {
    domainIncludes: ['starclub', 'finnlines', 'localhost:8081'],
    logoUrl: '/login/img/FinnlinesGrimaldilogoWhiteRGB.png',
    logoBgColor: '#003379',
    themeColor: '#003379',
    showLogoOnLoginBoxHeader: true,
    loginBoxBgColor: '#eee',
    buttonColor: '#5694C8',
    termsPortalNameRegex: /Self-service/,
    termsOfServiceUrl: 'https://www.finnlines.com/freight/good-to-know/guidelines-and-procedures/ssci-terms-of-service/',
    languageSelectButtonColor: '#003379',
  },
}
